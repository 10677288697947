import React from 'react';
import { Link } from 'gatsby';
import styles from './xupfooter.module.scss';

export default function XupFooter() {
  return (
    <footer className={styles.footer}>
      <ul className={styles.footerUl}>
        <li>© {(new Date().getFullYear())} XUP, Inc. </li>
        <li className={styles.medium}>
          <Link to="/legal/terms-of-service">Terms of Service</Link>
        </li>
        <li className={styles.medium}>
          <Link to="/legal/privacy-policy">Privacy</Link>
        </li>
        <li className={styles.small}>
          <Link to="/legal/terms-of-service">Legal Shit</Link>
        </li>
      </ul>
    </footer>
  );
}