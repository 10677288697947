import React from 'react';
import cx from 'classnames';

export default function container({ location, children }) {
  return (
    <div
      className={cx('app', {
        '--experiences': location.pathname.includes('experiences'),
      })}
    >
      {children}
    </div>
  );
}
